.purchase-general-info-wrapper {
  width: 50%;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 14px;
}
@media (max-width: 768px) {
  .purchase-general-info-wrapper {
    padding: 8px;
    width: 100%;
  }
}
.purchase-general-info-wrapper .purchase-general-info_check {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0px;
  margin-bottom: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-general-info-wrapper .purchase-general-info_check b {
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0px;
}
.purchase-general-info-wrapper .purchase-general-info_check a {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  gap: 8px;
  color: rgba(121,185,40,0.988);
}
.purchase-general-info-wrapper .purchase-general-info_check a span {
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0px;
}
@media (max-width: 500px) {
  .purchase-general-info-wrapper .purchase-general-info_check a span {
    display: none;
  }
}
@media (max-width: 500px) {
  .purchase-general-info-wrapper .purchase-general-info_check a svg {
    width: 24px;
    height: 24px;
  }
}
.purchase-general-info-wrapper .purchase-general-info_status {
  position: relative;
}
.purchase-general-info-wrapper .purchase-general-info_status .state {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
}
.purchase-general-info-wrapper .purchase-general-info_status .state span {
  font-size: 20px;
  line-height: 1.3;
}
@media (max-width: 768px) {
  .purchase-general-info-wrapper .purchase-general-info_status .state span {
    font-size: 18px;
  }
}
.purchase-general-info-wrapper .purchase-general-info_status .state .icon {
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  color: #fff;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
}
.purchase-general-info-wrapper .purchase-general-info_status .state .icon-mo-cancel {
  color: #fff;
}
.purchase-general-info-wrapper .purchase-general-info_status .state .icon-mo-cancel svg {
  width: 4.5px;
  height: 4.5px;
}
.purchase-general-info-wrapper .purchase-general-info_status .state.new .icon {
  background: #ff9500;
}
.purchase-general-info-wrapper .purchase-general-info_status .state.ready .icon {
  background: #79b928;
}
.purchase-general-info-wrapper .purchase-general-info_status .state.refused .icon {
  background: #747474;
}
.purchase-general-info-wrapper .purchase-general-info_status .date {
  margin-left: 24px;
  margin-top: 6px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.3;
}
.purchase-general-info-wrapper .purchase-general-info_status .change-time,
.purchase-general-info-wrapper .purchase-general-info_status .cancel-order {
  position: absolute;
  right: 0;
  top: 0;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #79b928;
  cursor: pointer;
  border-radius: 5px;
  padding: 2px;
}
.purchase-general-info-wrapper .purchase-general-info_status .change-time .icon,
.purchase-general-info-wrapper .purchase-general-info_status .cancel-order .icon {
  width: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.purchase-general-info-wrapper .purchase-general-info_status .change-time span,
.purchase-general-info-wrapper .purchase-general-info_status .cancel-order span {
  margin-left: 8px;
}
@media (max-width: 500px) {
  .purchase-general-info-wrapper .purchase-general-info_status .change-time span,
  .purchase-general-info-wrapper .purchase-general-info_status .cancel-order span {
    display: none;
  }
}
.purchase-general-info-wrapper .purchase-general-info_status .cancel-order {
  top: 30px;
  color: #ef4237;
}
.purchase-general-info-wrapper .purchase-general-info_address,
.purchase-general-info-wrapper .purchase-general-info_comment {
  margin-top: 14px;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  padding: 8px;
  position: relative;
}
@media (max-width: 768px) {
  .purchase-general-info-wrapper .purchase-general-info_address,
  .purchase-general-info-wrapper .purchase-general-info_comment {
    margin-top: 8px;
  }
}
.purchase-general-info-wrapper .purchase-general-info_address .general-info,
.purchase-general-info-wrapper .purchase-general-info_comment .general-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.purchase-general-info-wrapper .purchase-general-info_address .description,
.purchase-general-info-wrapper .purchase-general-info_comment .description {
  line-height: 16px;
  font-size: 14px;
  margin-left: 8px;
}
@media (max-width: 768px) {
  .purchase-general-info-wrapper .purchase-general-info_address .description,
  .purchase-general-info-wrapper .purchase-general-info_comment .description {
    font-size: 12px;
  }
}
.purchase-general-info-wrapper .purchase-general-info_address .value,
.purchase-general-info-wrapper .purchase-general-info_comment .value {
  display: inline-block;
  line-height: 16px;
  font-size: 16px;
  font-family: "ExoBold", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-left: 24px;
  margin-top: 6px;
}
@media (max-width: 768px) {
  .purchase-general-info-wrapper .purchase-general-info_address .value,
  .purchase-general-info-wrapper .purchase-general-info_comment .value {
    font-size: 14px;
  }
}
.purchase-general-info-wrapper .purchase-general-info_address .icon-faq-arrow,
.purchase-general-info-wrapper .purchase-general-info_comment .icon-faq-arrow {
  top: 8px;
  right: 8px;
  color: #5a4435;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: box !important;
  display: flex !important;
}
.purchase-general-info-wrapper .purchase-general-info_comment .general-info {
  height: 24px;
  cursor: pointer;
}
.purchase-general-info-wrapper .purchase-general-info_comment .general-info .icon-warning {
  color: #ffc107;
}
.purchase-general-info-wrapper .purchase-general-info_comment .value {
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.purchase-general-info-wrapper .purchase-general-info_comment .general-info.isActive .icon-faq-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.purchase-general-info-wrapper .purchase-general-info_comment .general-info.isActive .icon-warning {
  color: #a2a2a2;
}
/*# sourceMappingURL=src/js/pages/PurchasesPage/components/PurchasesList/components/PurchaseItemGeneralInfo/PurchaseItemGeneralInfo.css.map */