@import "~vars"

.purchase-general-info-wrapper
	width 50%
	border 1px solid #F7F7F7
	border-radius 10px
	padding 14px
	+tablet()
		padding 8px
		width 100%

	.purchase-general-info_check
		font-weight: 400
		font-size: 20px
		line-height: 130%
		letter-spacing: 0px
		margin-bottom 14px
		display flex
		justify-content space-between
		align-items center
		& b
			font-weight: 600
			font-size: 20px
			line-height: 130%
			letter-spacing: 0px
		& a
			display flex
			align-items center
			gap 8px
			color #79B928FC
			& span
				font-weight: 400
				font-size: 14px
				line-height: 138%
				letter-spacing: 0px
				+phone()
					display none
			& svg
				+phone()
					width 24px
					height 24px

	.purchase-general-info_status
		position relative
		.state
			display flex
			align-items center
			font-family $exo-bold
			span
				font-size 20px
				line-height 1.3
				+tablet()
					font-size 18px
			.icon
				size(16px)
				display flex
				color white
				justify-content center
				align-items center
				border-radius 50%
				margin-right 8px
			.icon-mo-cancel
				color white
				svg
					size(4.5px)
			&.new
				.icon
					background #FF9500
			&.ready
				.icon
					background $primary
			&.refused
				.icon
					background #747474
		.date
			margin-left 24px
			margin-top 6px
			display inline-block
			font-size 14px
			line-height 1.3
		.change-time, .cancel-order
			position absolute
			right 0
			top 0
			height 24px
			display flex
			align-items center
			color $primary
			cursor pointer
			border-radius 5px
			padding 2px
			.icon
				width 24px
				display flex
				justify-content center
			span
				margin-left 8px
			+phone()
				span
					display none
		.cancel-order
			top 30px
			color #EF4237

	.purchase-general-info_address,
	.purchase-general-info_comment
		margin-top 14px
		border-radius 10px
		border 1px solid #F7F7F7
		padding 8px
		position relative
		+tablet()
			margin-top 8px
		.general-info
			display flex
			align-items center
		.description
			line-height 16px
			font-size 14px
			margin-left 8px
			+tablet()
				font-size 12px
		.value
			display inline-block
			line-height 16px
			font-size 16px
			font-family $exo-bold
			font-weight 600
			margin-left 24px
			margin-top 6px
			+tablet()
				font-size 14px
		.icon-faq-arrow
			top 8px
			right 8px
			color #5A4435
			display flex !important
	.purchase-general-info_comment
		.general-info
			height 24px
			cursor pointer
			.icon-warning
				color #FFC107
		.value
			font-family $exo
			font-weight 500
		.general-info.isActive
			.icon-faq-arrow
				transform rotate(180deg)
			.icon-warning
				color $gray


